<template>
  <div>
    <el-tooltip
      effect="dark"
      placement="top"
      :content="tipMsg"
      :disabled="value == trueLabel"
    >
      <div
        class="word-checkbox"
        :class="[value == trueLabel ? 'active' : '']"
        @click="handleCheck"
      >
        <i
          :class="[
            'iconfont',
            value == trueLabel ? 'icon-weixiao' : 'icon-weixiao1'
          ]"
        />
        <label v-if="$slots.default">
          <slot></slot>
        </label>
      </div>
    </el-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      default: 0
    },
    trueLabel: {
      type: [String, Number],
      default: 1
    },
    falseLabel: {
      type: [String, Number],
      default: 0
    },
    tipMsg: {
      type: String,
      default: '标记已掌握'
    }
  },
  methods: {
    handleCheck() {
      const newValue =
        this.value == this.trueLabel ? this.falseLabel : this.trueLabel
      this.$emit('input', newValue)
      this.$emit('change', newValue)
    }
  }
}
</script>

<style scoped lang="scss">
.word-checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #bfbfbf;
  &.active {
    > label {
      color: #1f1f40;
    }
  }
  &:hover {
    .icon-weixiao1 {
      color: #ffc06a;
    }
    > label {
      color: #1f1f40;
    }
  }
  > i {
    font-size: 18px;
    &.icon-weixiao {
      color: #ffc06a;
    }
  }
  > label {
    font-size: 14px;
    font-weight: normal;
    margin-left: 6px;
    cursor: pointer;
  }
}
</style>
